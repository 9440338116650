var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.isColumn ? "flex mv3 justify-between mt4" : "flex flex-column",
    },
    [
      !_vm.isColumn
        ? _c(
            "div",
            [_c("OverviewBanner", { attrs: { isLargeBanner: false } })],
            1
          )
        : _vm._e(),
      _vm.isColumn
        ? _c("div", { staticClass: "flex justify-between" }, [
            _c(
              "div",
              {
                staticClass:
                  "pa4 bg-adori-light-gray br2 mb4 flex flex-column ba b--adori-gray w-30 justify-between",
              },
              [
                _vm._m(0),
                _c(
                  "div",
                  [
                    _vm.$permissions.isCreateEpisodeAllowed()
                      ? _c("BaseButtonRed", {
                          staticClass: "add-podcast w-100",
                          attrs: {
                            text: "Upload a new track",
                            onClick: _vm.handleUploadAudio,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "pa4 bg-adori-light-gray br2 mb4 flex flex-column ba b--adori-gray w-30 justify-between",
              },
              [
                _vm._m(1),
                _c(
                  "div",
                  [
                    _vm.$permissions.isViewTagShowAllowed("")
                      ? _c("BaseButtonRed", {
                          staticClass: "add-podcast mr2 w-100",
                          attrs: {
                            text: "Learn more about interactive tags",
                            onClick: _vm.handleLearnTags,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "pa4 bg-adori-light-gray br2 mb4 flex flex-column ba b--adori-gray w-30 justify-between",
              },
              [
                _vm._m(2),
                _c(
                  "div",
                  [
                    _vm.$permissions.isViewInsightsAllowed()
                      ? _c("BaseButtonRed", {
                          staticClass: "add-podcast mr2 ph4 w-100 mt3",
                          attrs: {
                            text: "Adori Insights demo",
                            onClick: _vm.handleDemoInsights,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            ),
          ])
        : _vm._e(),
      !_vm.isColumn
        ? _c("div", [
            _c(
              "div",
              {
                staticClass:
                  "pa4 bg-adori-light-gray br2 mb4 flex flex-column ba b--adori-gray justify-between",
              },
              [
                _vm._m(3),
                _c(
                  "div",
                  [
                    _vm.$permissions.isCreateEpisodeAllowed()
                      ? _c("BaseButtonRed", {
                          staticClass: "add-podcast w-100",
                          attrs: {
                            text: "Upload a new track",
                            onClick: _vm.handleUploadAudio,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "pa4 bg-adori-light-gray br2 mb4 flex flex-column ba b--adori-gray justify-between",
              },
              [
                _vm._m(4),
                _c(
                  "div",
                  [
                    _vm.$permissions.isViewTagShowAllowed("")
                      ? _c("BaseButtonRed", {
                          staticClass: "add-podcast mr2 w-100",
                          attrs: {
                            text: "Learn more about interactive tags",
                            onClick: _vm.handleLearnTags,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "f4 mb4 light-gray lh-title tc" }, [
        _vm._v("Adorify your audio"),
      ]),
      _c("div", { staticClass: "mb3 flex items-center" }, [
        _c("img", {
          staticStyle: { width: "150px", height: "115px", margin: "auto" },
          attrs: { src: require("@/assets/no-episodes.svg"), alt: "" },
        }),
      ]),
      _c("div", { staticClass: "w-100 lh-copy silver custom-font-size" }, [
        _c("div", { staticClass: "mb-custom-px" }, [
          _vm._v("Transcripts with keyword detection."),
        ]),
        _c("div", { staticClass: "mb-custom-px" }, [
          _vm._v(
            "Automatically suggested interactive tags are visualized within the audio."
          ),
        ]),
        _c("div", { staticClass: "mb-custom-px" }, [
          _vm._v(
            "Publish as interactive podcasts, embeddable web content, and more!"
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "f4 mb4 light-gray lh-title tc" }, [
        _vm._v("Embed interactive tags"),
      ]),
      _c("div", { staticClass: "mb3 flex w-100" }, [
        _c("img", {
          staticStyle: { width: "150px", margin: "auto", height: "115px" },
          attrs: { src: require("@/assets/no-tags-new.svg"), alt: "" },
        }),
      ]),
      _c("div", { staticClass: "w-100 lh-13 silver custom-font-size" }, [
        _c("div", { staticClass: "mb-custom-px" }, [
          _vm._v("Create interactive elements for audio."),
        ]),
        _c("div", { staticClass: "mb-custom-px" }, [
          _vm._v(
            "\n            Tags surface contextual visuals, links, polls and more from within the audio.\n          "
          ),
        ]),
        _c("div", { staticClass: "mb-custom-px" }, [
          _vm._v("Engage your listeners with enhanced narratives."),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "f4 mb4 light-gray lh-title tc" }, [
        _vm._v("Access advanced analytics"),
      ]),
      _c("div", { staticClass: "mb3 flex items-center" }, [
        _c("img", {
          staticStyle: { width: "150px", margin: "auto", height: "115px" },
          attrs: { src: require("@/assets/no-insights.svg"), alt: "" },
        }),
      ]),
      _c("div", { staticClass: "w-100 lh-copy silver custom-font-size" }, [
        _c("div", { staticClass: "mb-custom-px" }, [
          _vm._v("Detailed podcast analytics."),
        ]),
        _c("div", { staticClass: "mb-custom-px" }, [
          _vm._v(
            "Downloads, location and device metrics for shows and episodes."
          ),
        ]),
        _c("div", { staticClass: "mb-custom-px" }, [
          _vm._v(
            "Unique engagement analytics for audio consumed in Adori enabled apps."
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "f4 mb4 light-gray lh-title tc" }, [
        _vm._v("Adorify your audio"),
      ]),
      _c("div", { staticClass: "mb3 flex items-center" }, [
        _c("img", {
          staticStyle: { width: "150px", height: "115px", margin: "auto" },
          attrs: { src: require("@/assets/no-episodes.svg"), alt: "" },
        }),
      ]),
      _c("div", { staticClass: "w-100 lh-copy silver custom-font-size" }, [
        _c("div", { staticClass: "mb-custom-px" }, [
          _vm._v("Transcripts with keyword detection."),
        ]),
        _c("div", { staticClass: "mb-custom-px" }, [
          _vm._v(
            "Automatically suggested interactive tags are visualized within the audio."
          ),
        ]),
        _c("div", { staticClass: "mb-custom-px" }, [
          _vm._v(
            "Publish as interactive podcasts, embeddable web content, and more!"
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "f4 mb4 light-gray lh-title tc" }, [
        _vm._v("Embed interactive tags"),
      ]),
      _c("div", { staticClass: "mb3 flex w-100" }, [
        _c("img", {
          staticStyle: { width: "150px", margin: "auto", height: "115px" },
          attrs: { src: require("@/assets/no-tags-new.svg"), alt: "" },
        }),
      ]),
      _c("div", { staticClass: "w-100 lh-13 silver custom-font-size" }, [
        _c("div", { staticClass: "mb-custom-px" }, [
          _vm._v("Create interactive elements for audio."),
        ]),
        _c("div", { staticClass: "mb-custom-px" }, [
          _vm._v(
            "\n            Tags surface contextual visuals, links, polls and more from within the audio.\n          "
          ),
        ]),
        _c("div", { staticClass: "mb-custom-px" }, [
          _vm._v("Engage your listeners with enhanced narratives."),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }